'use client'
import { UserProvider } from '@/features/user/contexts/userContext'
import { LoadingProvider } from '@/hooks/useLoading'
import { SnackbarProvider } from '@/hooks/useSnackbar'
import { AppRouterCacheProvider } from '@mui/material-nextjs/v15-appRouter'
import { Suspense, type FC, type ReactNode } from 'react'

export const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Suspense>
      <AppRouterCacheProvider>
        <SnackbarProvider>
          <LoadingProvider>
            <UserProvider>{children}</UserProvider>
          </LoadingProvider>
        </SnackbarProvider>
      </AppRouterCacheProvider>
    </Suspense>
  )
}
